// divider_cover max 40rem

.divider_cover {

  .divider_cover_container {

    .divider_cover_headlines {

      h2 {
        font-size: 1.75rem;

        em {
          font-size: 2.25rem;
        }
      }
    }
  }
}