// cover max 40rem

.cover {

  .cover_container {

    .cover_headlines {

      h1 {
        font-size: 3rem;

        em {
          font-size: 3rem;
        }
      }
    }
  }
}